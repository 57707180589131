/* Navbar container */
.nav {
    background-color: #333;
    overflow: hidden;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 100;
    top: 0;
}

.VIIlogo {
    border-radius: 20%;
}

/* Site title */
.site-title {
    font-size: 1.5rem;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

/* Navbar links */
.nav-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

/* Navbar link items */
.nav-links li {
    margin-left: 20px;
}

/* Navbar links */
.nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

/* Adjusting the icon inside the link */
.nav-links img {
    margin-right: 8px;
    vertical-align: middle;
}

/* Hover effect for links */
.nav-links a:hover {
    background-color: #575757;
    border-radius: 5px;
}

.site-title img {
    margin-right: 8px;
    vertical-align: middle;
}

/* Responsive adjustments for mobile */
.hamburger {
    display: none; /* Initially hidden */
    cursor: pointer;
    color: white;
}

/* Mobile styles */
@media (max-width: 768px) {
    .nav {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .VIIlogo {
        border-radius: 20%;
        width: 50px;
        display: block;
    }

    .site-title {
        font-size: 1rem;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
    }

    .nav-links {
        display: none; /* Hide nav links initially on mobile */
        flex-direction: column;
        width: 100%;
        position: relative;
        top: 20px; /* Adjust to align below navbar */
        left: 0;
        background-color: #333;
        z-index: 10000;
    }

    .nav ul a {
        color: #fff;
        text-decoration: none;
        /* margin-bottom: 5px; */
    }
    
    .nav-links-mobile {
        display: flex; /* Show links when hamburger is clicked */
        flex-direction: column;
        align-items: left;
        width: 100%;
        position: relative;
        top: 5px;
        left: 0;
        background-color: #333;
        list-style-type: none;
        color: #fff;
        text-decoration: none;
        outline: none;
    }

    .nav-links {
        list-style: none; /* Removes bullet points */
        padding: 0;       /* Removes default padding */
        margin: 0;        /* Removes default margin */
        display: none;    /* Set display style as needed */
        align-items: center;
    }
    

    .nav-links a {
        width: 100%;
        text-align: center;
        justify-content: center;
        text-decoration: none;
        color: #fff;
        outline: none;
    }

    .hamburger {
        display: block; /* Show hamburger icon on mobile */
    }
}
